import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "../ContentBlock";
import ButtonServer, { ButtonIntent, ButtonStretch } from "../ui/button";
import { StarsWithReviews } from "../Reviews/StarsWithReviews";
import { IS_HAM, MINISTORE } from "@/lib/const";
import type { RatingScores } from "../Reviews/Stars";
import { SingleIcon } from "./Icon.client";
interface Props {
  block: Payload.ContentIconBlock;
}
export const ContentIconBlockServer = ({
  block
}: Props) => {
  return <div className={block.darkBackground ? "text-white" : "text-gray-900"} data-sentry-component="ContentIconBlockServer" data-sentry-source-file="index.tsx">
      <ContentBlockServer className="flex flex-col items-center px-4 pt-8 pb-6 mb-4 md:px-20 lg:px-32" fullWidthBgColor={block.darkBackground ? "bg-gray-800" : "bg-gray-100"} data-sentry-element="ContentBlockServer" data-sentry-source-file="index.tsx">
        <h2 className="mb-4 text-3xl font-bold text-center">
          {block.headline}
        </h2>
        <p className="mb-6 text-lg font-normal leading-[1.333rem] text-center">
          {block.subtitle}
        </p>
        <ul className="grid grid-cols-2 gap-6 mb-8 md:gap-6 md:grid-cols-4">
          {block?.icons?.map(icon => <SingleIcon key={icon.id} icon={icon} />)}
        </ul>
        {block.ctaContent && block.ctaUrl && <>
            <ButtonServer content={block.ctaContent} stretch={ButtonStretch.full} url={block.ctaUrl} intent={ButtonIntent.CTA} className="mb-4 md:hidden md:invisible" />
            <ButtonServer content={block.ctaContent} url={block.ctaUrl} intent={ButtonIntent.CTA} className="invisible hidden mb-4 md:block md:visible" />
          </>}
        <div className="mx-auto mb-5 md:mx-0 max-w-fit md:max-w-none">
          <StarsWithReviews rating={parseFloat(block.starsRating as string) as RatingScores} reviews={block.reviewsAmount} vertical overrideColor={MINISTORE === "baerskintactical" ? "#1BB57C" : "#fbbf24"} dark={IS_HAM ? false : true} data-sentry-element="StarsWithReviews" data-sentry-source-file="index.tsx" />
        </div>
      </ContentBlockServer>
    </div>;
};
export default ContentIconBlockServer;